import React from 'react'
import styled from 'styled-components'

interface Iprops {
  title: string
  MyImg: string
  subtitle: string
}

const PropsCards: React.FC<Iprops> = ({ title, MyImg, subtitle }) => {
  return (
    <Container>
      <Card>
        <Imag src={MyImg} />

        <TextHolder>
          <Text1>{subtitle}</Text1>
          <Text2>{title}</Text2>
        </TextHolder>
      </Card>
    </Container>
  )
}

export default PropsCards

const Container = styled.div``
const Card = styled.div`
  height: 500px;
  width: 400px;
  background-color: white;
  color: black;
  border-radius: 20px;
  overflow: hidden;
  margin: 10px;
`
const Imag = styled.img`
  height: 250px;
  width: 100%;
  /* background-color: black; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
    transition: all 350ms;
    filter: grayscale(1);
  }
`
const TextHolder = styled.div`
  margin-left: 10px;
  justify-content: space-between;
  line-height: 30px;
`

const Text1 = styled.div`
  font-size: 30px;
  font-weight: 20px;
  color: #4cba89;
  padding-top: 20px;
  padding-bottom: 20px;
`
const Text2 = styled.div`
  /* font-weight: bold; */
  font-size: 18px;
  color: #797979;
`
