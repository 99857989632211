import React from 'react'
import styled from 'styled-components'
import PropsCards from './PropsCard'
import cat from './assets/cat.jpg'
import matches from './assets/match.jpg'
import guy from './assets/coding.jpg'
import fire from './assets/fire.jpg'
import smile from './assets/bear.jpg'
import pc from './assets/pc.jpg'
import coder from './assets/coder.jpg'

const Blog = () => {
  return (
    <Container id="blog">
      <h1>Our Blog & News</h1>
      <Stroke></Stroke>
      <CardContainer>
        <h1>
          We are committed to these <br /> 5 major goals
        </h1>

        <Props>
          <PropsCards
            MyImg={guy}
            subtitle="Moving Youth Into Tech"
            title="Our goal is to support 50,000 women between  2022 and 2025. Working with our direct partners to help women, single mothers and girls move into tech."
          />
          <PropsCards
            MyImg={coder}
            subtitle="Valerian Pedro"
            title="Our goal is to support 50,000 women between  2022 and 2025. Working with our direct partners to help women, single mothers and girls move into tech."
          />
          <PropsCards
            MyImg={pc}
            subtitle="Moving  Codelab to the world"
            title="Our goal is to support 50,000 women between  2022 and 2025. Working with our direct partners to help women, single mothers and girls move into tech."
          />
          <PropsCards
            MyImg={matches}
            subtitle="Moving Women Into Tech"
            title="Our goal is to support 50,000 women between  2022 and 2025. Working with our direct partners to help women, single mothers and girls move into tech."
          />
          <PropsCards
            MyImg={cat}
            subtitle="Moving Women Into Tech"
            title="Our goal is to support 50,000 women between  2022 and 2025. Working with our direct partners to help women, single mothers and girls move into tech."
          />
          <PropsCards
            MyImg={fire}
            subtitle="Moving Women Into Tech"
            title="Our goal is to support 50,000 women between  2022 and 2025. Working with our direct partners to help women, single mothers and girls move into tech."
          />
        </Props>
      </CardContainer>
    </Container>
  )
}

export default Blog
const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 1400px;

  h1 {
    color: #1f2470;
    font-size: 40px;
    font-weight: bolder;
  }
`
const Stroke = styled.div`
  height: 10px;
  width: 250px;
  border-radius: 5px;
  background-image: linear-gradient(
    90deg,
    rgba(230, 37, 125, 1) 49%,
    rgba(242, 112, 4, 1) 94%
  );
`
const CardContainer = styled.div`
  height: 1300px;
  width: 100%;
  margin-top: 20px;
  border-radius: 50px;
  box-shadow: 1px 2px 9px 2px;
  /* background-color: blue; */
  background-image: linear-gradient(
    90deg,
    rgba(230, 37, 125, 1) 49%,
    rgba(242, 112, 4, 1) 94%
  );

  h1 {
    font-size: 40px;
    text-align: center;
    padding-top: 30px;
    color: white;
  }
`
const Props = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 10fr);
  grid-gap: 3px;
  padding: 30px;
`
