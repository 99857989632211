import React from 'react'
import styled from 'styled-components'
import AgencyComp from './AgencyComp/AgencyComp'
import Blog from './Blog'
import Features from './Features'
import Footer from './Footer'
import Hero from './Hero'
import Specialization from './Specialization'

const HomeScreen = () => {
  return (
    <Container>
      <Hero />
      <AgencyComp />
      <Specialization />
      <Features />
      <Blog />
      <Footer />
    </Container>
  )
}

export default HomeScreen

const Container = styled.div``
