import React from 'react'
import logo from './logo.svg'
import './App.css'
import Header from './Components/Header/Header'
import HomeScreen from './Components/HomeScreen'

function App() {
  return (
    <div>
      <Header />
      <HomeScreen />
    </div>
  )
}

export default App
