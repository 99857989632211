import React from 'react'
import styled from 'styled-components'
import feature from './assets/feature-banner.png'
import MusicImg from './assets/feel.jpg'

const Features = () => {
  return (
    <Container id="features">
      <h1>Our Features</h1>
      <Stroke></Stroke>
      <Div>
        <First>
          <Mini>
            <DivImg>
              <MusicsImg src={MusicImg} />
            </DivImg>
            <Text>
              <h2>Idea & Analysis</h2>
              <p>
                Praesent tincidunt congue est ut <br /> hendrerit. Pellentesque
                et eros sit <br /> amet ipsum venenatis.
              </p>
            </Text>
          </Mini>
          <Mini>
            <DivImg>
              <MusicsImg src={MusicImg} />
            </DivImg>
            <Text>
              <h2>Designing</h2>
              <p>
                Praesent tincidunt congue est ut <br /> hendrerit. Pellentesque
                et eros sit <br /> amet ipsum venenatis.
              </p>
            </Text>
          </Mini>
        </First>
        <Second>
          <FeatureImg src={feature} />
        </Second>
        <First>
          <Mini>
            <DivImg>
              <MusicsImg src={MusicImg} />
            </DivImg>
            <Text>
              <h2>Development</h2>
              <p>
                Praesent tincidunt congue est ut <br /> hendrerit. Pellentesque
                et eros sit <br /> amet ipsum venenatis.
              </p>
            </Text>
          </Mini>
          <Mini>
            <DivImg>
              <MusicsImg src={MusicImg} />
            </DivImg>
            <Text>
              <h2>Testing and Launching</h2>
              <p>
                Praesent tincidunt congue est ut <br /> hendrerit. Pellentesque
                et eros sit <br /> amet ipsum venenatis.
              </p>
            </Text>
          </Mini>
        </First>
      </Div>
    </Container>
  )
}

export default Features

const Mini = styled.div`
  display: flex;
  padding-right: 20px;
`
const Max = styled.div``

const FeatureImg = styled.img`
  :hover {
    cursor: pointer;

    transform: scale(1.1);
  }
`
const MusicsImg = styled.img`
  height: 60px;
  width: 60px;
  object-fit: cover;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  border-radius: 500px;
`

const DivImg = styled.div``
const Text = styled.div``
const Div = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  /* background-color: blue; */
  height: 420px;
  padding-left: 50px;
  padding-right: 30px;
`
const First = styled.div`
  display: flex;
  /* background-color: red; */
  flex-direction: column;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
`
const Second = styled.div`
  padding-top: 50px;
`
const Stroke = styled.div`
  height: 10px;
  width: 250px;
  border-radius: 5px;
  background-image: linear-gradient(
    90deg,
    rgba(230, 37, 125, 1) 49%,
    rgba(242, 112, 4, 1) 94%
  );
`

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h1 {
    color: #1f2470;
    font-size: 50px;
    font-weight: bolder;
  }
  h2 {
    color: #1f2470;
    font-weight: bolder;
    font-size: 30px;
  }
  p {
    color: grey;
    font-weight: semi-bold;
    font-size: 19px;
    padding-bottom: 10px;
  }
`
