import React from 'react'
import styled from 'styled-components'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import { MdCall, MdEmail, MdLocationPin } from 'react-icons/md'

const Footer = () => {
  return (
    <Container id="contact">
      <Text>
        <h1>Resolution</h1>
        <p>
          Maecenas pellentesque placerat quam, in finibus nisl <br /> tincidunt
          sed. Aliquam magna augue, malesuada ut <br /> feugiat eget, cursus
          eget felis.
        </p>
        <IconHolder>
          <IconHold>
            <BsFacebook />{' '}
          </IconHold>
          <IconHold>
            {' '}
            <BsInstagram />{' '}
          </IconHold>
          <IconHold>
            {' '}
            <BsTwitter />
          </IconHold>
        </IconHolder>
      </Text>
      <Max>
        <Major>
          <First>
            <h2>Our Link</h2>
            <Nav>Home</Nav>
            <Nav>About Us</Nav>
            <Nav>Services</Nav>
            <Nav>Team</Nav>
            <Nav>Blog</Nav>
          </First>
          <First>
            <h2>Our Services</h2>
            <Nav>Strategy & Research</Nav>
            <Nav>Web Development</Nav>
            <Nav>Web Solution</Nav>
            <Nav>Digital Marketing</Nav>
            <Nav>App Design</Nav>
          </First>
          <First>
            <h2>Other Links</h2>
            <Nav>FAQ</Nav>
            <Nav>Portfolio</Nav>
            <Nav>Privacy Policy</Nav>
            <Nav>Terms & Conditions</Nav>
            <Nav>Support</Nav>
          </First>
          <First>
            <h2>Contact Us</h2>
            <Hold>
              <Circle>
                <MdCall />
              </Circle>
              <Nav>
                +234-903-694- <br /> 3250 <br /> +234-803-717
                <br /> 1484
              </Nav>
            </Hold>
            <Hold>
              <Circle>
                <MdEmail />
              </Circle>
              <Nav>
                info@valerianpedro03@gmail.com
                <br />
                info@sylvia@gmail.com
              </Nav>
            </Hold>
            <Hold>
              <Circle>
                <MdLocationPin />
              </Circle>
              <Nav>
                45, Prince Fadina street, <br /> olodi-Apapa
              </Nav>
            </Hold>
          </First>
        </Major>
      </Max>
    </Container>
  )
}

export default Footer
const Max = styled.div`
  display: flex;
  flex-direction: column;
`

const Hold = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
`
const Circle = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    90deg,
    rgba(230, 37, 125, 1) 49%,
    rgba(242, 112, 4, 1) 94%
  );
  border-radius: 200px;
  /* color: white; */
  font-size: 20px;
`

const Nav = styled.div`
  color: white;
  padding: 10px;
  /* font-weight: bold; */
  font-size: 70px;

  font-size: 20px;
  :hover {
    border-bottom: 4px white dotted;
    cursor: pointer;
    background-image: linear-gradient(
      90deg,
      rgba(230, 37, 125, 1) 49%,
      rgba(242, 112, 4, 1) 94%
    );
    color: black;
  }
`
const IconHolder = styled.div`
width:100%;
display:flex;
margin-top;30px;
color:black;
/* background-color:black; */

`
const IconHold = styled.div`
  color: #ef1f76;
  /* background-image: linear-gradient(
    90deg,
    rgba(230, 37, 125, 1) 49%,
    rgba(242, 112, 4, 1) 94%
  ); */
  /* background-color: red; */
  display: flex;
  /* flex-direction: row; */
  height: 35px;
  margin-right: 30px;
  /* margin-bottom: 10px; */
  padding-left: 30px;
  padding-right: 30px;
  width: 35px;
  font-size: 22px;
  /* background-color: white; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;

  :hover {
    background-image: linear-gradient(
      90deg,
      rgba(230, 37, 125, 1) 49%,
      rgba(242, 112, 4, 1) 94%
    );
    color: white;
  }
`
const Container = styled.div`
  background-color: #4a2478;
  /* background-color: red; */
  margin-top: 30px;
  height: 700px;
  h1 {
    color: white;
    font-size: 50px;
    margin-bottom: 30px;
    margin-top: 30px;
    /* padding-bottom: 20px; */
    /* font-weight: bold; */
  }
  p {
    /* padding-top: 30px; */
    color: white;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: white;
    font-size: 35px;
  }
`
const Text = styled.div`
  /* background-color: red; */
  height: 300px;
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
`
const Major = styled.div`
  /* background-color: black; */
  display: flex;
  /* flex-direction: column; */
  height: 150px;
  align-items: center;
  justify-content: center;
  width: 1500px;
  justify-content: space-between;
`
const First = styled.div`
  /* background-color: orange; */
  height: 200px;
  width:800px
  margin-top: 30px;
  margin: 30px;
  display: flex;
  flex-direction: column;
`
